import React from "react"
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Animate from "../components/animate"
import { title, teamList, teamInfo, teamLink, teamLogo } from '../styles/_teamList.module.scss'

const RetatedList = ({ order }) => {
  const { allMdx } = useStaticQuery(
    graphql`
      query {
        allMdx {
          nodes {
            id
            body
            frontmatter {
              name
              slug
              order
              jobcategories
              tags
              logo {
                alt
                src {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  let nodes = allMdx.nodes.filter(
    node => {
      if (node.body) {
        return node
      }
      return false
    }
  )

  //前後の記事
  nodes.sort(function (a, b) {
    return a.frontmatter.order - b.frontmatter.order;
  });
  let num = nodes.findIndex(node => node.frontmatter.order === order);

  let related = [];
  switch (num) {
    case nodes.length - 1:
      related[0] = nodes[nodes.length - 2];
      related[1] = nodes[0];
      break;
    case 0:
      related[0] = nodes[nodes.length - 1];
      related[1] = nodes[1];
      break;
    case 1:
      related[0] = nodes[0];
      related[1] = nodes[2];
      break;
    case 2:
      related[0] = nodes[1];
      related[1] = nodes[3];
      break;
    case 3:
      related[0] = nodes[2];
      related[1] = nodes[4];
      break;
    case 4:
      related[0] = nodes[3];
      related[1] = nodes[5];
      break;
    case 5:
      related[0] = nodes[4];
      related[1] = nodes[6];
      break;
    case 6:
      related[0] = nodes[5];
      related[1] = nodes[7];
      break;
    case 7:
      related[0] = nodes[6];
      related[1] = nodes[8];
      break;
    case 8:
      related[0] = nodes[7];
      related[1] = nodes[9];
      break;
    case 9:
      related[0] = nodes[8];
      related[1] = nodes[10];
      break;
    case 10:
      related[0] = nodes[9];
      related[1] = nodes[11];
      break;
    case 11:
      related[0] = nodes[10];
      related[1] = nodes[12];
      break;
    case 12:
      related[0] = nodes[11];
      related[1] = nodes[13];
      break;
    default:
      related[0] = nodes[9];
      related[1] = nodes[0];
      break;
  }

  if (related.length) {

    return (
      <aside>
        <section>
          <div className={title}>
            <h2 className="title3">その他のチーム</h2>
          </div>
          <div className={teamList}>
            {
              related.map(node => (
                <section key={node.id} className={teamInfo}>
                  <Animate className="fadeIn" triggerOnce={true}>
                    <Link
                      to={`../${node.frontmatter.slug}/`}
                      className={teamLink}
                    >
                      <h3 className="title4">{node.frontmatter.name}</h3>
                      <figure className={teamLogo}>
                        <GatsbyImage
                          image={getImage(node.frontmatter.logo.src)}
                          alt={node.frontmatter.logo.alt}
                          placeholder="transparent"
                          loading="lazy"
                        />
                      </figure>
                    </Link>
                  </Animate>
                </section>
              ))
            }
          </div>
        </section>
      </aside >
    )
  }
}

export default RetatedList
