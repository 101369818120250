// extracted by mini-css-extract-plugin
export var article = "_pageTeamDetail-module--article--HR5Jx";
export var careers = "_pageTeamDetail-module--careers--gdH6L";
export var gotoTeam = "_pageTeamDetail-module--gotoTeam--XG+hS";
export var info = "_pageTeamDetail-module--info--RCnnV";
export var jobcategories = "_pageTeamDetail-module--jobcategories--CrbVn";
export var jobcategory = "_pageTeamDetail-module--jobcategory--S7LKB";
export var join = "_pageTeamDetail-module--join--xRjFo";
export var tag = "_pageTeamDetail-module--tag--5-7gB";
export var tags = "_pageTeamDetail-module--tags--64jHd";
export var title1 = "_pageTeamDetail-module--title1--s5GWO";
export var wrapper = "_pageTeamDetail-module--wrapper--fldfF";